import React, { useState } from "react";
import "./login.css";
//auth
import { useAuthContext } from "../hooks/authcontext";
//ant design
import { Spin } from "antd";
import { Input } from "antd";
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import { Alert } from "antd";
// import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
// import { Button, Input, Space } from "antd";
// import { Popover } from "antd";
//Api
import { authSignIn } from "../api/loginAPI";
//
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//react router
import { useNavigate } from "react-router-dom";
//

const Login = () => {
  //
  const { login } = useAuthContext();
  //declarations
  const navigate = useNavigate();
  //spin state
  const [loading, setLoading] = React.useState<boolean>(false);
  //const [logoLoading, setLogoLoading] = React.useState<boolean>(false);
  //states
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  //
  const notify_error = (message: any) =>
    toast.error(`error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });
  const notify_success = async (message: any) =>
    toast.success(`Message: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });
  //
  const HandleSignIn = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      var call: any = await authSignIn({
        // tenantID: tenantId,
        email: Email,
        password: Password,
      });
      console.log(call);
      if (!call) {
        notify_error("Server error");
      } else {
        notify_success(call.data.message);
        // navigate("/landing", { replace: true });
        // Assuming login is successful, set isAuthenticated to true
        setTimeout(() => {
          login().then(() => {
            navigate("/landing", { replace: true });
          });

          //navigate("/landing", { replace: true });
        }, 2000); // Delay navigation to allow the toast to display
      }
    } catch (err: any) {
      console.log(err);
      if (!err.response.data) {
        notify_error(err.message);
      } else if (err.response.data) {
        notify_error(err.response.data || err);
      } else {
        notify_error(err);
      }
      // notify(err.response.data || err);
      // notify(err);
    } finally {
      setLoading(false); // Set loading to false after request completion or error
    }
  };
  //
  return (
    <div className="AdminLogin">
      <ToastContainer />
      <div className="header">
        <h1>HabSync</h1>
      </div>
      <div className="loginContainer">
        <h2>Welcome Back, Admin</h2>
        <Spin spinning={loading}>
          <form onSubmit={HandleSignIn} className="loginForm">
            <div className="formGroup">
              <input
                type="email"
                placeholder="Email"
                className="inputField"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input.Password
                placeholder="Password"
                className="inputField"
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit" className="loginButton">
                Login
              </button>
            </div>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default Login;

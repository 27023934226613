import React, { useState, useEffect, useCallback } from "react";
import "./newSharedTenant.css";
// Ant Design
import { Button, Steps, Spin, theme } from "antd";
//import { Tooltip } from "antd";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";
// React Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// API methods
import { addsharedTenants } from "../../../api/addTenantAPI";
import { getAllModule } from "../../../api/moduleAPI";

const NewSharedTenant = () => {
  // Form states
  const [tenantName, setTenantName] = useState("");
  const [tenantEmail, setTenantEmail] = useState("");
  const [tenantCPName, setTenantCPName] = useState("");
  const [tenantCPEmail, setTenantCPEmail] = useState("");
  const [tenantCPNumber, setTenantCPNumber] = useState("");
  const [tenantSuperAdminName, setTenantSuperAdminName] = useState("");
  const [tenantSuperAdminEmail, setTenantSuperAdminEmail] = useState("");
  const [tenantSuperAdminMobile, setTenantSuperAdminMobile] = useState("");
  // const [tenantDBHost, setTenantDBHost] = useState("");
  // const [tenantDBName, setTenantDBName] = useState("");
  // const [tenantDBPassword, setTenantDBPassword] = useState("");
  // const [tenantDBUser, setTenantDBUser] = useState("");
  // //modules list state
  const [ModuleList, setModuleList] = useState<any[]>([]);
  //checkbos
  // const [CheckUserModule, setCheckUserModule] = useState(false);
  const [checkedModules, setCheckedModules] = useState([]);

  //

  // Notification components
  const notifyError = (message: string) =>
    toast.error(`Error! \n ${message}`, {
      position: "top-center",
      theme: "dark",
    });

  const notifySuccess = (message: string) =>
    toast.success(`Success: \n ${message}`, {
      position: "top-center",
      theme: "colored",
    });

  // Spin state
  const [loading, setLoading] = useState<boolean>(false);

  // Ant Design Steps
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const contentStyle: React.CSSProperties = {
    // lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  // Custom Methods
  const handleAddSharedTenant = async () => {
    try {
      setLoading(true);
      await addsharedTenants({
        TenantName: tenantName,
        TenantEmail: tenantEmail,
        // TenantDBHost: tenantDBHost,
        // TenantDBName: tenantDBName,
        ContactPersonName: tenantCPName,
        ContactPersonEmail: tenantCPEmail,
        subscriptionModules: checkedModules,
        SAdminName: tenantSuperAdminName,
        SAdminEmail: tenantSuperAdminEmail,
        SAdminMobile: tenantSuperAdminMobile,
      });
      notifySuccess("Tenant added successfully!");
    } catch (error: any | Error) {
      //notifyError(err.message || "Something went wrong.");
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        notifyError(error.response.data || "Something went wrong.");
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        notifyError(error.request || "Something went wrong.");
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        notifyError(error.message || "Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  };

  //featch modules
  const GetModules = useCallback(async () => {
    try {
      var req: any = await getAllModule();
      setModuleList(req.data);
      console.log(req.data);
    } catch (err: any) {
      notifyError(err.response.data);
    }
  }, []); // empty dependency array, because nothing inside GetModules changes.

  useEffect(() => {
    GetModules();
  }, [GetModules]);

  const onChangeCheckModule: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    const moduleId: number = e.target.value;
    setCheckedModules((prevCheckedModules: any) => {
      if (prevCheckedModules.includes(moduleId)) {
        return prevCheckedModules.filter((id: number) => id !== moduleId);
      } else {
        return [...prevCheckedModules, moduleId];
      }
    });
    console.log("Checked Modules: ", checkedModules);
  };
  //

  // Steps Configuration
  const steps = [
    {
      title: "Tenant Information",
      content: (
        <div className="TenantInfo">
          <div>
            <h6>Basic</h6>
            <label htmlFor="tenant-name">Tenant Name:</label>
            <input
              id="tenant-name"
              type="text"
              placeholder="Tenant Name"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
              required
            />

            <label htmlFor="tenant-email">Tenant Email:</label>
            <input
              id="tenant-email"
              type="email"
              placeholder="Tenant Email"
              value={tenantEmail}
              onChange={(e) => setTenantEmail(e.target.value)}
              required
            />

            <label>
              Estimated Number of Users:
              <input type="number" placeholder="1" min="1"></input>
            </label>
          </div>
          <div>
            <h6>Address</h6>
            <label>
              Country
              <input defaultValue="Ethiopia" readOnly></input>
            </label>

            <label>
              City
              <input type="text"></input>
            </label>

            <label>
              ZIP/Postal Code
              <input type="text"></input>
            </label>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Address Information",
    //   content: (
    //     <div className="AddressInfo">
    //       <label htmlFor="address">Address:</label>
    //       <input type="text" id="address" placeholder="Address" />
    //       <label htmlFor="city">City:</label>
    //       <input type="text" id="city" placeholder="City" />
    //       <label htmlFor="state">State:</label>
    //       <input type="text" id="state" placeholder="State" />
    //       <label htmlFor="zip">Zip Code:</label>
    //       <input type="text" id="zip" placeholder="Zip Code" />
    //       <label htmlFor="country">Country:</label>
    //       <input type="text" id="country" placeholder="Country" />
    //     </div>
    //   ),
    // },
    {
      title: "Primary Person / Super Admin",
      content: (
        <div className="ContactPerson">
          <label htmlFor="contact-person-name">Contact Person Name:</label>
          <input
            id="contact-person-name"
            type="text"
            placeholder="Contact Person Name"
            value={tenantSuperAdminName}
            onChange={(e) => setTenantSuperAdminName(e.target.value)}
            required
          />

          <label htmlFor="contact-email">
            Contact Email:
            <input
              id="contact-email"
              type="email"
              placeholder="Contact Email"
              value={tenantSuperAdminEmail}
              onChange={(e) => setTenantSuperAdminEmail(e.target.value)}
              required
            />
          </label>

          <label htmlFor="contact-phone">
            Contact Phone:
            <input
              type="tel"
              id="contact-phone"
              placeholder="Contact Phone"
              value={tenantSuperAdminMobile}
              onChange={(e) => setTenantSuperAdminMobile(e.target.value)}
              required
            />
          </label>
        </div>
      ),
    },
    {
      title: "Primary System Administrator",
      content: (
        <div className="ContactPerson">
          <label htmlFor="contact-person-name">Contact Person Name:</label>
          <input
            id="contact-person-name"
            type="text"
            placeholder="Contact Person Name"
            value={tenantCPName}
            onChange={(e) => setTenantCPName(e.target.value)}
            required
          />

          <label htmlFor="contact-email">
            Contact Email:
            <input
              id="contact-email"
              type="email"
              placeholder="Contact Email"
              value={tenantCPEmail}
              onChange={(e) => setTenantCPEmail(e.target.value)}
              required
            />
          </label>

          <label htmlFor="contact-phone">
            Contact Phone:
            <input
              type="tel"
              id="contact-phone"
              placeholder="Contact Phone"
              value={tenantCPNumber}
              onChange={(e) => setTenantCPNumber(e.target.value)}
            />
          </label>
        </div>
      ),
    },
    {
      title: "System Configeration",
      content: (
        <div className="ContactPerson">
          <label>
            Default Currency:
            <input type="text" defaultValue="ETB" required readOnly />
          </label>
        </div>
      ),
    },
    {
      title: "Module Configuration",
      content: (
        <div>
          <h2>Pick Your Tools</h2>
          <div className="moduleCheckboxContainer">
            {ModuleList.map((module: any) => (
              <div key={module.id}>
                <Checkbox value={module.id} onChange={onChangeCheckModule}>
                  <div className="moduleCheckBox">
                    <img
                      className="moduleIcon"
                      src={module.iconUrl}
                      alt={module.name}
                    />
                    {module.name}
                  </div>
                </Checkbox>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className="NewSharedTenantDis">
      <ToastContainer />
      <Spin spinning={loading}>
        <>
          <Steps current={current} items={items} />
          <div className="AntDesignStep" style={contentStyle}>
            {steps[current].content}
          </div>
          <div style={{ marginTop: 24 }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={next}>
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                loading={loading}
                onClick={handleAddSharedTenant}
              >
                Done
              </Button>
            )}
            {current > 0 && (
              <Button style={{ margin: "0 8px" }} onClick={prev}>
                Previous
              </Button>
            )}
          </div>
        </>
      </Spin>
    </div>
  );
};

export default NewSharedTenant;
